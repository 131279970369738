import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BillsDepartmentsStoreEffects } from './effects';
import { billsDepartmentsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('billsDepartmentsStore', billsDepartmentsReducer),
    EffectsModule.forFeature([BillsDepartmentsStoreEffects]),
  ],
})
export class BillsDepartmentsStoreModule {}
