import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { BillDepartment } from '../../models';
import { BillsDepartmentsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class BillsDepartmentsStoreEffects {
  constructor(
    private dataService: BillsDepartmentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, filters }) =>
        this.dataService.read(propertiesIds, filters).pipe(
          map(({ data }: IResponseSuccess<BillDepartment[]>) => {
            return fromActions.loadSuccess({ billDepartments: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ billDepartment }) =>
        this.dataService.create(billDepartment).pipe(
          map(({ data }: IResponseSuccess<BillDepartment>) => {
            return fromActions.createSuccess({ billDepartment: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ billDepartment }) =>
        this.dataService.update(billDepartment).pipe(
          map(({ data }: IResponseSuccess<BillDepartment>) => {
            return fromActions.updateSuccess({ billDepartment: data });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ billDepartmentId }) =>
        this.dataService.delete(billDepartmentId).pipe(
          map(() => {
            return fromActions.deleteSuccess({ billDepartmentId });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
