import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { billDepartments }) => {
    return fromState.featureAdapter.setAll(
      (billDepartments || []).map((billDepartment) => {
        return {
          ...billDepartment,
          property_id: billDepartment['property_department'].map(
            (propertyDepartment) => propertyDepartment.property_id,
          ),
        };
      }),
      {
        ...state,
        loading: false,
        error: null,
      },
    );
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { billDepartment }) =>
    fromState.featureAdapter.setOne(billDepartment, {
      ...state,
      loading: false,
      error: null,
    }),
  ),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.updateRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    fromActions.updateSuccess,
    (state, { billDepartment: { id, ...billDepartment } }) =>
      fromState.featureAdapter.updateOne(
        { id, changes: billDepartment },
        {
          ...state,
          loading: false,
          error: null,
        },
      ),
  ),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { billDepartmentId }) =>
    fromState.featureAdapter.removeOne(billDepartmentId, {
      ...state,
      loading: false,
    }),
  ),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(fromActions.resetState, () => ({
    ...fromState.initialState,
  })),
);

export function billsDepartmentsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
