import { createAction, props } from '@ngrx/store';

import { BillDepartment } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Bills Departments] Load Request',
  props<{ propertiesIds: number[]; filters?: SearchParams }>(),
);
export const loadSuccess = createAction(
  '[Bills Departments] Load Success',
  props<{ billDepartments: BillDepartment[] }>(),
);
export const loadFailure = createAction(
  '[Bills Departments] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Bills Departments] Create Request',
  props<{ billDepartment: Partial<BillDepartment> }>(),
);
export const createSuccess = createAction(
  '[Bills Departments] Create Success',
  props<{ billDepartment: BillDepartment }>(),
);
export const createFailure = createAction(
  '[Bills Departments] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Bills Departments] Update Request',
  props<{ billDepartment: BillDepartment }>(),
);
export const updateSuccess = createAction(
  '[Bills Departments] Update Success',
  props<{ billDepartment: BillDepartment }>(),
);
export const updateFailure = createAction(
  '[Bills Departments] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Bills Departments] Delete Request',
  props<{ billDepartmentId: number }>(),
);
export const deleteSuccess = createAction(
  '[Bills Departments] Delete Success',
  props<{ billDepartmentId: number }>(),
);
export const deleteFailure = createAction(
  '[Bills Departments] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Bills Departments] Reset State');
