import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'lodash';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>(
  'billsDepartmentsStore',
);

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectGroupedByProduction = createSelector(
  selectAllItems,
  (items) => groupBy(items, 'production_type'),
);

export const selectGroupedByVatQuote = createSelector(selectAllItems, (items) =>
  groupBy(items, 'vat_quote_id'),
);

export const selectError = createSelector(
  selectState,
  (state: State) => state.error,
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.loading,
);
